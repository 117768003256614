<template>
  <div class="create-bank-account-page">
    <Title v-if="isOnboard" class="mx-auto">Add bank account</Title>
    <BackTitle v-else confirm-click @click="goBack">Add bank account</BackTitle>
    <Form class="bank-account-form" v-slot="{ invalid, changed }">
      <div class="create-bank-account-page__formWrapper">
        <FormItem rules="required" v-slot="{ isError }">
          <Input
            v-model="form.accountHolderName"
            label="Account holder name"
            placeholder="Account holder name"
            :isError="isError"
          />
        </FormItem>
        <FormItem rules="required" v-slot="{ isError }">
          <Select
            v-model="form.country"
            :options="countryOptions"
            label="Country"
            placeholder="Country"
            :isError="isError"
          />
        </FormItem>
        <FormItem rules="required" v-slot="{ isError }">
          <Input
            v-model="form.accountNumber"
            label="Account number"
            placeholder="Account number"
            :isError="isError"
          />
        </FormItem>
        <FormItem rules="required" v-slot="{ isError }">
          <Input
            v-model="form.routingNumber"
            label="Routing number"
            placeholder="Routing number"
            :isError="isError"
            tooltip="A routing number is your 5 digit transit number followed by your three digit institution number"
            tooltipPosition="top-end"
          />
        </FormItem>
      </div>
      <div class="bank-account-form__actions mt-auto">
        <Button
          is-block
          :is-loading="isSubmitting"
          :is-disabled="invalid || !changed"
          @click="submit"
        >
          Add account
        </Button>
        <Button
          v-if="isOnboard && isSuperadmin"
          variant="light"
          is-block
          @click="handleSkipBankAccountSetup"
        >
          Skip
        </Button>
      </div>
    </Form>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { onboardBankAccountPathName } from "@/helpers/onboard";
import { NEW_BANK_ACCOUNT_MOCK } from "@/helpers/mocks";
import { getCurrencyByCountryCode, getSortedCountries } from "@/helpers/utils";
import { getStripeClient } from "@/plugins/stripe";
import alert from "@/plugins/alert";
import BankAccountsService from "@/api/services/BankAccountsService";
import { mapActions, mapState } from "vuex";

export default {
  name: "CreateBankAccountPage",
  components: { BackTitle },
  data() {
    return {
      form: { ...NEW_BANK_ACCOUNT_MOCK },
      isSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      isSuperadmin: (state) => state.users.isSuperadmin,
    }),
    isOnboard() {
      return this.$route.name === onboardBankAccountPathName;
    },
    countryOptions() {
      return getSortedCountries().map((country) => ({
        value: country.id,
        name: country.name,
      }));
    },
  },
  methods: {
    ...mapActions({
      fetchVenue: "venues/fetchVenue",
    }),
    goBack() {
      this.$router.push({
        name: "BankAccounts",
      });
    },
    async submit() {
      try {
        this.isSubmitting = true;
        const { country, accountNumber, accountHolderName, routingNumber } =
          this.form;
        const stripeClient = await getStripeClient();
        const tokenResult = await stripeClient.createToken("bank_account", {
          country,
          currency: getCurrencyByCountryCode(country).code,
          routing_number: routingNumber,
          account_number: accountNumber,
          account_holder_name: accountHolderName,
        });
        if (tokenResult.error) {
          alert.open({
            message: tokenResult.error.message,
            variant: "danger",
            delay: 10000,
          });
        } else {
          const bankAccountToken = tokenResult.token.id;
          await BankAccountsService.createBankAccount({
            bankAccountToken,
            isDefaultForCurrency: this.isOnboard,
          });
          if (this.isOnboard) {
            await this.fetchVenue();
            await this.$router.push("/");
          } else {
            await this.$router.push({
              name: "BankAccounts",
            });
          }
        }
      } finally {
        this.isSubmitting = false;
      }
    },
    handleSkipBankAccountSetup() {
      this.$store.commit("users/SET_IS_SKIP_BANK_ACCOUNT_SETUP", true);
      this.$router.push({
        name: "Home",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-bank-account-page,
.bank-account-form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.create-bank-account-page {
  &__formWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
</style>
