import { loadStripe } from "@stripe/stripe-js";

let stripeClient = null;

export const getStripeClient = async () => {
  if (!stripeClient) {
    stripeClient = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
  }
  return stripeClient;
};
